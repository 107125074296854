import * as Common from 'components/_universal/Common';
import React, { ReactNode } from 'react';
import shortId from 'shortid';
import styled, { css } from 'styled-components';

export const SquaresGridWrapper = styled(Common.Div)(
  () =>
    css`
      display: grid;
      grid-column-gap: 32px;
      grid-row-gap: 32px;
      grid-template-columns: repeat(1, 1fr);

      @media (min-width: 800px) {
        grid-template-columns: repeat(2, 255px);
      }

      @media (min-width: 1300px) {
        grid-template-columns: repeat(4, 255px);
      }
    `
);

export const ImpactSquare = styled(Common.Div)<{
  variant?: 'company';
}>(
  ({ theme: { colors }, variant }) =>
    css`
      aspect-ratio: 1;
      max-width: min(255px, 100%);
      max-height: 255px;
      display: flex;
      background: ${colors.background};
      flex-direction: column;
      align-items: flex-start;
      padding: 48px 12px 12px 24px;
      flex: 1;

      opacity: 0.8;

      p,
      h2,
      h3 {
        color: ${colors.greylikeText};
      }

      h2 {
        font-size: 50px;
      }

      ${variant &&
      css`
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
      `};
    `
);

interface ISquareGridProps {
  items: {
    content: ReactNode;
  }[];
  className?: string;
}

export const SquareGrid = ({ items, className }: ISquareGridProps) => {
  return (
    <SquaresGridWrapper className={className}>
      {items.map(({ content, variant }) => (
        <ImpactSquare variant={variant} key={shortId.generate()}>
          {content}
        </ImpactSquare>
      ))}
    </SquaresGridWrapper>
  );
};
