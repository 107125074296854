import Laptop from 'assets/images/_infoGraphics/laptop.png';
import DawidBFace from 'assets/images/About/people/dawid_b.png';
import TomaszFace from 'assets/images/About/people/tomasz_m.png';
import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import { ArrowButton } from 'components/_universal/ArrowButton';
import * as Common from 'components/_universal/Common';
import { SquareGrid } from 'components/_universal/SquareGrid';
import { navigate } from 'gatsby';
import React from 'react';
import { aboutItems, peopleItems } from 'sections/About/aboutData';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import { mediaQueries } from 'shared/breakpoints';
import { ROUTE_DEEPTECH, ROUTE_JOIN_US } from 'shared/paths';
import styled, { css } from 'styled-components';
import { B0, B1, B2, B3, B4, H1, H3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const PeopleWrapper = styled(Common.Div)<{}>(
  () =>
    css`
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      margin-top: 64px;

      ${mediaQueries.lg} {
        width: calc(190px * 3 + 32px * 2);
      }
    `
);

export const SqueezedName = styled(B1)<{}>(
  () =>
    css`
      font-size: 18px;
      letter-spacing: -0.8px;
    `
);

export const PersonSquare = styled(Common.Svg)(
  () =>
    css`
      max-width: 317px;
      aspect-ratio: 1;

      @media (max-width: 450px) {
        width: 100%;
        max-width: none;
      }
    `
);

export default () => {
  const isMobile = useBreakpoint('md');
  const isSm = useBreakpoint('sm');

  return (
    <CommonMargin>
      <Section
        title="Codahead is team of business analysts, managers, developers, engineers commited to your tech transformation. "
        variant="secondary"
      />
      <Section>
        <Common.Div mb={8}>
          <SquareGrid items={aboutItems} />
        </Common.Div>
        <H1 mb={8}>Leadership</H1>
        <Common.Div flex="column" pl={isMobile ? 0 : 8} maxW="1435px">
          <B1 mb={2}>
            Our strength lies in the deep tech understanding and digital development experience. We know our clients
            operational aspects and their business objectives and align them with the most relevant and consistent
            technologies.
          </B1>
          <B2 mb={isSm ? 4 : 13}>
            We provide end-to-end digital services and advisory bringing decade of IT <br /> expertise to enable the
            success of our clients around the globe.
          </B2>

          <Common.Div flex={isSm ? 'column' : 'row'} gap={isSm ? '32px' : '128px'} mb={12}>
            <div>
              <PersonSquare src={TomaszFace} />
              <B0 mt={4} bold={true}>
                Tomasz Marona
              </B0>
              <B2>Chief Operating Officer</B2>
            </div>
            <Common.Div maxW="860px">
              <B1 mb={4}>
                Tom is an entrepreneurial leader with passion, perseverance and determination that moves Codahead
                forward. He oversees all aspects of business operations and corporate direction. Tomasz personally gets
                in touch with every client to make sure all their needs are understood and fulfilled. He’s commited to
                our clients success.
              </B1>
              <B2 mb={isMobile ? 0 : 8}>
                Tom has over twenty years of product-development and management experience - created and scaled multiple
                products and built and run over a dozen high-performance teams.
              </B2>
            </Common.Div>
          </Common.Div>

          {isSm && (
            <div>
              <PersonSquare src={DawidBFace} />
              <B0 mt={4} bold={true}>
                Dawid Bury
              </B0>
              <B2 mb={4}>Chief Technology Officer</B2>
            </div>
          )}

          <Common.Div flex={isSm ? 'column' : 'row'} gap={isSm ? '32px' : '128px'}>
            <Common.Div maxW="860px">
              <B1 mb={4}>
                Dawid is in charge of crafting and delivering the technology direction for the company. His close
                collaboration with clients and project managers ensures finding the best solutions to fit in with the
                clients needs.
              </B1>
              <B2 mb={8}>
                Dawid is experienced executive with MS engineering degree. He oversees Codaheads’ architecture and user
                experience teams and collaborates on innovation projects.
              </B2>
            </Common.Div>
            {!isSm && (
              <div>
                <PersonSquare src={DawidBFace} />
                <B0 mt={4} bold={true}>
                  Dawid Bury
                </B0>
                <B2>Chief Technology Officer</B2>
              </div>
            )}
          </Common.Div>
        </Common.Div>
      </Section>
      <Section title="People are the key" variant="secondary">
        <PeopleWrapper>
          {peopleItems.map(({ key, fullName, position, src }) => (
            <div key={key}>
              <Common.Svg src={src} mb={1} w="190px" h="217px" />
              <SqueezedName mb={1}>{fullName}</SqueezedName>
              <B4>{position}</B4>
            </div>
          ))}
        </PeopleWrapper>
      </Section>
      <Section title="Tech transformation">
        <B1 mb={6} mt={8} maxW="860px">
          We render tech transformation services that aid businesses in their intentions to automate operations by
          introducing solutions to drive the digital transformation agenda and produce more value for their customers.
        </B1>
        <B3 mb={8}>
          Codaheads success has resulted from a relentless focus on core principles: client <br /> interest and success,
          client service, client confidentiality.
        </B3>
        <ArrowButton onClick={() => navigate(ROUTE_DEEPTECH)} text="explore" />
        <Common.Div
          flex={isMobile ? 'column' : 'row'}
          gap={isMobile ? '32px' : '128px'}
          mb={isMobile ? 0 : 4}
          mt={isMobile ? 4 : 8}
        >
          <Common.Svg src={Laptop} w={isSm ? 'auto' : '496px'} h={isSm ? 'auto' : '375px'} />
          <Common.Div>
            <H3 mb={2}>Careers</H3>
            <B3 mb={8}>
              Interested in joining our team?
              <br /> We're a tight-knit group of professionals who are experts in
              <br /> delivering digital projects. You can be one of us!
              <br />
              <br /> We value diversity Codahead is more than just an inclusive
              <br />
              company, we are a community of people who support each
              <br /> other. We don't discriminate; instead, you can succeed with
              <br /> what makes you different!
            </B3>
            <ArrowButton onClick={() => navigate(ROUTE_JOIN_US)} text="explore" />
          </Common.Div>
        </Common.Div>
      </Section>
      <TakeThisStepSection />
      <TrustedBySection />
    </CommonMargin>
  );
};
